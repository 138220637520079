var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"showcase showcase-base"},[_c('div',[_c('Navbar')],1)]),_c('section',{attrs:{"id":"contact-form"}},[(_vm.errorMsg)?_c('vs-alert',{staticClass:"vs-alert",attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handlePasswordReset($event)}}},[_c('i',{staticClass:"user-icon fas fa-user-circle"}),_c('h2',[_vm._v("Update Password")]),_c('div',{staticClass:"center content-inputs"},[_c('vs-input',{staticClass:"vs-input",attrs:{"type":"password","placeholder":"Current Password","danger":_vm.$v.payload.currentPassword.$dirty &&
              !_vm.$v.payload.currentPassword.required},model:{value:(_vm.$v.payload.currentPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.currentPassword, "$model", $$v)},expression:"$v.payload.currentPassword.$model"}}),(
            _vm.$v.payload.currentPassword.$dirty &&
              !_vm.$v.payload.currentPassword.required
          )?_c('span',{staticClass:"error"},[_vm._v(" Current Password is required ")]):_vm._e()],1),_c('div',{staticClass:"center content-inputs"},[_c('vs-input',{staticClass:"vs-input",attrs:{"type":"password","placeholder":"New Password","danger":_vm.$v.payload.newPassword.$dirty && !_vm.$v.payload.newPassword.required},model:{value:(_vm.$v.payload.newPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.newPassword, "$model", $$v)},expression:"$v.payload.newPassword.$model"}})],1),(
          _vm.$v.payload.newPassword.$dirty && !_vm.$v.payload.newPassword.required
        )?_c('span',{staticClass:"error"},[_vm._v(" New Password is required ")]):_vm._e(),_c('div',{staticClass:"center content-inputs"},[_c('vs-input',{staticClass:"vs-input",attrs:{"type":"password","placeholder":"New Password","danger":_vm.$v.payload.newConfirmPassword.$dirty &&
              !_vm.$v.payload.newConfirmPassword.required},model:{value:(_vm.$v.payload.newConfirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.newConfirmPassword, "$model", $$v)},expression:"$v.payload.newConfirmPassword.$model"}})],1),(
          _vm.$v.payload.newConfirmPassword.$dirty &&
            !_vm.$v.payload.newConfirmPassword.required
        )?_c('span',{staticClass:"error"},[_vm._v(" New Password Confirm is required ")]):_vm._e(),(!_vm.loading)?_c('button',{staticClass:"form-btn",attrs:{"disabled":_vm.invalid}},[_vm._v(" Submit Now ")]):_vm._e(),(_vm.loading)?_c('span',{staticClass:"loader"},[_c('half-circle-spinner',{attrs:{"animation-duration":1000,"size":30,"color":_vm.color}})],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }