<template>
  <div>
    <section id="footer">
      <div class="footer-div">
        <router-link to="/"
          ><img
            class="logo"
            src="../assets/images/leobit_logo.png"
            alt=""
            srcset=""
        /></router-link>
        <p class="first-para">
          We offer a simple and easy to use platform for investors acquire high
          profits over short periods of time.
        </p>
      </div>

      <div class="footer-div other">
        <h3>Quick Links</h3>
        <span class="underline"></span>
        <div class="links">
          <router-link class="footer-link" to="/">Home</router-link>
          <router-link class="footer-link" to="/about">About</router-link>
          <router-link class="footer-link" to="/blog">Blog</router-link>
          <router-link class="footer-link" to="/team">Our Team</router-link>
          <router-link class="footer-link" to="/contact"
            >Contact Us</router-link
          >
        </div>
      </div>

      <div class="footer-div other">
        <h3>Contact Us</h3>
        <span class="underline"></span>
        <p>
          2307 Sundown Lane, Austin, Texas, USA
        </p>
        <p>+1 (862) 298 -3683</p>
        <p>investmenteasy343@gmail.com</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
#footer {
  background-color: #041e37;
  color: #fff;
  font-size: 15px;
}

.footer-div {
  margin: 15px 0;
}

.footer-div h3 {
  font-size: 24px;
  margin: 8px 0;
}

.logo {
  width: 100px;
}

.underline {
  height: 4px;
  width: 30px;
  background-color: var(--brandcolor);
  display: block;
}
.links {
  margin: 20px 0;
}

.footer-link {
  display: block;
  text-decoration: none;
  color: #fff;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  #footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .first-para {
    width: 80%;
  }
}
</style>
