<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
      </div>
    </section>

    <section id="contact-form">
      <vs-alert v-if="passwordMessage" class="vs-alert" color="success">
        {{ passwordMessage }}
      </vs-alert>

      <vs-alert v-if="errorMsg" class="vs-alert" color="danger">
        {{ errorMsg }}
      </vs-alert>

      <form @submit.prevent="sendPasswords">
        <i class="user-icon fas fa-user-circle"></i>

        <h2>Reset Password</h2>

        <div class="center content-inputs">
          <vs-input
            class="vs-input"
            type="password"
            placeholder="Password"
            v-model="$v.payload.password.$model"
            :danger="
              $v.payload.password.$dirty && !$v.payload.password.required
            "
          />

          <span
            class="error"
            v-if="$v.payload.password.$dirty && !$v.payload.password.required"
          >
            Password is required
          </span>
        </div>

        <div class="center content-inputs">
          <vs-input
            class="vs-input"
            type="password"
            placeholder="Confrim Password"
            v-model="$v.payload.confirmPassword.$model"
            :danger="
              $v.payload.confirmPassword.$dirty &&
                !$v.payload.confirmPassword.required
            "
          />
        </div>

        <span
          class="error"
          v-if="
            $v.payload.confirmPassword.$dirty &&
              !$v.payload.confirmPassword.required
          "
        >
          Password Confirm is required
        </span>

        <button :disabled="invalid" v-if="!loading" class="form-btn">
          Submit Now
        </button>

        <span class="loader" v-if="loading">
          <half-circle-spinner
            :animation-duration="1000"
            :size="30"
            :color="color"
          />
        </span>
      </form>
    </section>
  </div>
</template>

<script>
import Navbar from './Navbar';
import { mapActions, mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import { HalfCircleSpinner } from 'epic-spinners';
import { bus } from '../main';

export default {
  name: 'ResetPassword',
  components: {
    Navbar,
    HalfCircleSpinner,
  },

  data: () => ({
    loading: false,
    color: '#e9d16f',
    token: '',
    payload: {
      password: '',
      confirmPassword: '',
    },
  }),

  methods: {
    ...mapActions(['resetPassword']),

    sendPasswords() {
      this.loading = true;
      this.resetPassword({ payload: this.payload, token: this.token });
    },
  },

  validations: {
    payload: {
      password: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        minLength: minLength(8),
      },
    },
  },

  computed: {
    ...mapGetters(['errorMsg', 'passwordMessage']),

    invalid() {
      return this.$v.payload.$invalid;
    },
  },

  created() {
    this.token = this.$route.params.token;

    bus.$on('stopLoading', () => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
}

.error {
  display: block;
  position: relative;
  bottom: 10px;
  color: #f57f6c;
  text-align: start;
  margin-top: 5px;
  font-size: 14px;
  padding: 10px 0 0 0;
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.showcase-header {
  border-left: 0.6rem solid var(--brandcolor);
}

.showcase-div h1 {
  color: #fff;
  padding: auto;
}

.contact {
  padding: 25px 5px;
}

.contact .contact-icon {
  color: var(--brandcolor);
  font-size: 2.7rem;
}

#contact-form {
  background-color: #fff;
  color: var(--blackcolor);
}

.content-inputs {
  margin: 10px 0;
}

.form-btn {
  display: block;
  background-color: var(--brandcolor);
  border: none;
  padding: 10px 20px;
  color: #fff;
  /* border-radius: 50px; */
  margin: 15px auto;
}

form {
  font-size: 16px;
  text-align: center;
  padding: 4rem 20px;
}

.user-icon {
  font-size: 5.5rem;
  color: var(--brandcolor);
}

.auth {
  display: flex;
  flex-direction: column;
  color: var(--blackcolor);
}

.auth span {
  display: block;
  margin: 5px auto;
}

.auth .auth-link {
  text-decoration: none;
  color: var(--brandcolor);
}

@media (min-width: 768px) {
  form {
    width: 45%;
    margin: 0 auto;
    font-size: 18px;
  }
}
</style>
