<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
        <div class="showcase-div">
          <div class="showcase-header">
            <h1>Our Services</h1>
          </div>
        </div>
      </div>
    </section>

    <section id="Services">
      <div>
        <h2>Our Amazing Service</h2>
        <p>
          We have the best possible services any organisation can offer.
        </p>

        <div class="services-div">
          <div class="service">
            <i class="service-icon fab fa-bitcoin"></i>
            <h2>Margin Trading</h2>
            <p>
              We help our clients trade bitcoin to earn more cash for them
              ,thereby taking of financial difficulties in the society.
            </p>
          </div>

          <div class="service">
            <i class="fas service-icon fa-lock"></i>
            <h2>Strong Security</h2>
            <p>
              Transactions on the bitcoin network is totally safe, as none of
              our agents have the access to your funds,so therefore clients
              funds and informations are kept totally safe.
            </p>
          </div>

          <div class="service">
            <i class="fas service-icon fa-exchange-alt"></i>
            <h2>Instant Exchange</h2>
            <p>
              We buy bitcoin also at a totally cheaper exchange rate, so
              therefore , your cash comes to you in a better amount.
            </p>
          </div>

          <div class="service">
            <i class="fas service-icon fa-mobile-alt"></i>
            <h2>Mobile App</h2>
            <p>
              With our developed auto trade bot softwares , trading has never
              gone bad,its always an absolutely correct signal for the progress
              of trade
            </p>
          </div>

          <div class="service">
            <i class="far service-icon fa-clock"></i>
            <h2>24/7 Trading</h2>
            <p>
              We are available 24 hours a day. 7 days a week to attend to and
              guarantee 100% satisfactiont
            </p>
          </div>

          <div class="service">
            <i class="fas service-icon fa-headset"></i>
            <h2>Free Consulting</h2>
            <p>
              Feel free to consult us anytime concerning anything as regards to
              live trading, and be sure to get a quick response
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="youNeed">
      <div class="image-content">
        <div class="image">
          <img src="../assets/images/ser_icon_2.png" alt="" sizes="" />
        </div>

        <div class="content">
          <h2>Quality</h2>

          <p>
            A meticulous thought process behind every decision and a constant
            striving for perfection, guides EasyInvestment to deliver the best
            possible experience for all of our users. From the moment a person
            is exposed to our logo, we wish to make sure that he feels our
            attention to even the minutest details at all times.
          </p>

          <div>
            <router-link class="content-link" to="/">read more</router-link>
          </div>
        </div>
      </div>

      <div class="image-content">
        <div class="content">
          <h4>Our About Important Descriptions</h4>
          <h2>Innovation</h2>
          <p>
            We take great pride in the fact that we have been around since the
            earliest days of the Fintech Revolution. Whether by introducing new
            and exciting ways to trade and invest online or by inventing
            cutting-edge financial products – we are, and always will be,
            innovators and disruptors in the Fintech space.
          </p>

          <div>
            <router-link class="content-link" to="/">read more</router-link>
          </div>
        </div>
        <div class="image image2">
          <img src="../assets/images/ser_icon_1.png" alt="" sizes="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from './Navbar';
export default {
  name: 'Services',
  components: {
    Navbar,
  },
};
</script>

<style>
.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.showcase-header {
  border-left: 0.6rem solid var(--brandcolor);
}

.showcase-div h1 {
  color: #fff;

  padding: auto;
}
#Services {
  text-align: center;
}

.service {
  text-align: center;
  padding: 20px 5px;
}

.service .service-icon {
  font-size: 5rem !important;
  color: var(--brandcolor);
}
/* 
#youNeed {
  background-color: #fff;
  color: var(--blackcolor);
} */

.content {
  text-align: center;
}

@media (min-width: 768px) {
  .showcase-div {
    padding: 5.5rem 20px;
  }

  .showcase-div h1 {
    color: #fff;
    padding: 5px;
  }

  .showcase-header {
    border-left: 0.6rem solid var(--brandcolor);
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    padding: 0px 10px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
    padding-right: 35px;
  }

  .image-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-bottom: 2rem;
  }

  .services-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }

  .content {
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image img {
    width: 70%;
  }

  .image2 {
    text-align: center;
  }
}
</style>
