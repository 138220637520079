<template>
  <div>
    <form @submit.prevent="sendDetails">
      <div class="image-div">
        <vs-avatar class="image" size="100" circle>
          <img :src="currentUser.profileImage" alt="" />
        </vs-avatar>
        <div class="user-details">
          <input
            style="display:none"
            type="file"
            @change="uploadFile"
            ref="fileInput"
          />
          <button
            v-if="!uploading"
            @click="$refs.fileInput.click()"
            class="upload-btn"
          >
            Upload Image
          </button>

          <span class="loader" v-if="uploading">
            <half-circle-spinner
              :animation-duration="1000"
              :size="30"
              :color="color"
            />
          </span>
        </div>
      </div>

      <div class="center content-inputs">
        <vs-input placeholder="Name" v-model="payload.name" />
      </div>

      <div class="center content-inputs">
        <vs-input placeholder="Username" v-model="payload.username" />
      </div>

      <!-- <div class="center content-inputs">
        <vs-input placeholder="Email" v-model="payload.email" />
      </div> -->

      <button v-if="!loading" class="form-btn">Submit Now</button>

      <div class="credentials">
        <span class="loader" v-if="loading">
          <half-circle-spinner
            :animation-duration="1000"
            :size="30"
            :color="color"
          />
        </span>

        <router-link class="update-password" to="/update-password"
          >Update Password</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { HalfCircleSpinner } from 'epic-spinners';
import { bus } from '../main';
export default {
  name: 'AccountSettings',
  components: {
    HalfCircleSpinner,
  },

  data: () => ({
    loading: false,
    uploading: false,
    color: '#e9d16f',
    payload: {
      name: '',
      // email: '',
      username: '',
    },
  }),

  methods: {
    ...mapActions(['updateUserData', 'updateMe']),

    uploadFile(event) {
      this.uploading = true;

      //Get File From Event
      const selectedFile = event.target.files[0];

      //Create new FormData Object
      const fd = new FormData();

      //Append File and file name to the new FormData
      fd.append('file', selectedFile, selectedFile.name);

      this.updateMe({ payload: fd });
    },

    sendDetails() {
      this.loading = true;
      this.updateMe({ payload: this.payload });
    },
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  created() {
    this.payload.name = this.currentUser.name;
    this.payload.username = this.currentUser.username;

    bus.$on('stopLoading', () => {
      this.loading = false;
      this.uploading = false;
    });
  },
};
</script>

<style scoped>
.upload-btn {
  color: var(--brandcolor);
  background: transparent;
  border: 1px solid var(--brandcolor);
  margin: 15px auto;
  padding: 10px 20px;
  border-radius: 50px;
}

.credentials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.loader {
  display: flex;
  justify-content: center;
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

form {
  color: var(--blackcolor);
}

.form-btn {
  display: block;
  background-color: var(--brandcolor);
  border: none;
  padding: 10px 20px;
  color: #fff;
  /* border-radius: 50px; */
  margin: 15px auto;
}

.user-details {
  color: #fff;
  justify-content: center;
}

.image-div {
  text-align: center;
}

.image-div .image {
  margin: 0 auto;
}

.update-password {
  text-decoration: none;
  color: var(--brandcolor);
}
</style>
