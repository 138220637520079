<template>
  <div>
    <div class="layout">
      <div class="welcome">
        <h2>
          Welcome <span class="brand">{{ currentUser.name }}</span>
        </h2>
      </div>

      <div class="cash-option">
        <h3>${{ currentUser.totalProfit }}</h3>
        <p>Total Profit</p>
      </div>

      <div class="cash-option">
        <h3>${{ currentUser.walletBalance }}</h3>
        <p>Wallet Balance</p>
      </div>

      <div class="cash-option">
        <h3>${{ currentUser.totalDeposit }}</h3>
        <p>Total Deposit</p>
      </div>

      <div class="btn-div">
        <button @click="active = !active">
          <i class="list-icon fas fa-wallet"></i>Deposit
        </button>
      </div>

      <div id="chart">
        <div
          style="height:560px; background-color: #1D2330; overflow:hidden; box-sizing: border-box; border: 1px solid #282E3B; border-radius: 4px; text-align: right; line-height:14px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #262B38;padding:1px;padding: 0px; margin: 0px; width: 100%;"
        >
          <div style="height:540px; padding:0px; margin:0px; width: 100%;">
            <iframe
              src="https://widget.coinlib.io/widget?type=chart&theme=dark&coin_id=859&pref_coin_id=1505"
              width="100%"
              height="536px"
              scrolling="auto"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              border="0"
              style="border:0;margin:0;padding:0;line-height:14px;"
            ></iframe>
          </div>
          <div
            style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"
          >
            <a
              href="https://coinlib.io"
              target="_blank"
              style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px"
              >Cryptocurrency Prices</a
            >&nbsp;by Coinlib
          </div>
        </div>
      </div>
    </div>

    <vs-dialog width="300px" not-center v-model="active">
      <template #header>
        <h4 class="header not-margin">Make A Deposit</h4>
      </template>

      <div class="con-content">
        <vs-alert color="success" v-show="isCopied">
          Copied to clipboard
        </vs-alert>
        <img src="../assets/images/barcode.jpg" style="width: 100%;" alt="" srcset="" />
        <div class="input-form">
          <vs-input
            value="1N7nrsPSfSEqaBv2aJktHHJHiwHArtCcQA"
            placeholder="Balance"
            disabled
          >
          </vs-input>
          <vs-button
            v-clipboard:copy="address"
            v-clipboard:success="displayAlert"
            border
          >
            <i class="fas fa-clipboard"></i>
          </vs-button>
        </div>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button class="ok-btn" @click="active = false" transparent>
            Ok
          </vs-button>
          <vs-button @click="active = false" dark transparent>
            Cancel
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Dashboard',

  data: () => ({
    active: false,
    address: '1N7nrsPSfSEqaBv2aJktHHJHiwHArtCcQA',
    isCopied: false,
  }),

  methods: {
    displayAlert() {
      this.isCopied = true;
    },
  },

  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>

<style scoped>
.layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.welcome,
#chart {
  grid-column: 1/4;
}

#chart {
  padding: 10px 0;
  height: auto;
}

.cash-option h3 {
  font-size: 1.6rem;
  margin: 5px auto;
  color: var(--brandcolor);
}

.brand {
  color: var(--brandcolor);
}

#crypt-candle-chart {
  height: 500px;
}

.btn-div button {
  display: block;
  padding: 5px 15px;
  font-size: 16px;
  background: var(--brandcolor);
  border: none;
  margin-bottom: 10px;
  font-family: var(--brandfont);
}

.con-content {
  text-align: center;
}

.con-footer {
  display: flex;
  justify-content: center;
}

.header {
  text-align: center;
}

.input-form {
  display: flex;
  width: 100%;
  justify-content: center;
}
@media (max-width: 420px) {
  .cash-option,
  .btn-div {
    grid-column: 1/3;
  }
}

@media (min-width: 768px) {
  .btn-div {
    display: none;
  }

  #chart {
    background-color: transparent !important;
    padding: 10px 0 !important;
  }
}
</style>
