import Vue from 'vue';
import App from './App.vue';

import routes from './routes';
import VueRouter from 'vue-router';
import Vuesax from 'vuesax';

import 'vuesax/dist/vuesax.css'; //Vuesax styles
import 'material-icons/iconfont/material-icons.css';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import VueClipboard from 'vue-clipboard2';
import Vuelidate from 'vuelidate';
import store from './store';

Vue.use(Vuesax);
Vue.use(VueRouter);
Vue.use(VueClipboard);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

require('./store/subscriber');

store.dispatch('attempt', localStorage.getItem('token'));

export const bus = new Vue();

export const router = new VueRouter({
  routes,
  mode: 'history',
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
