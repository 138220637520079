<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
        <div class="showcase-div">
          <div class="showcase-header">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
    </section>

    <div
      style="height:62px; background-color: #1D2330; overflow:hidden; box-sizing: border-box; border: 1px solid #282E3B; border-radius: 4px; text-align: right; line-height:14px; block-size:62px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #262B38;padding:1px;padding: 0px; margin: 0px; width: 100%;"
    >
      <div style="height:40px; padding:0px; margin:0px; width: 100%;">
        <iframe
          src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=no"
          width="100%"
          height="36px"
          scrolling="auto"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          border="0"
          style="border:0;margin:0;padding:0;"
        ></iframe>
      </div>
      <div
        style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"
      >
        <a
          href="https://coinlib.io"
          target="_blank"
          style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px"
          >Cryptocurrency Prices</a
        >&nbsp;by Coinlib
      </div>
    </div>

    <section id="GetInTouch">
      <h2>GET IN TOUCH</h2>
      <p>We Provide High Quality Services</p>

      <div class="contacts">
        <div class="contact">
          <i class="contact-icon fas fa-road"></i>
          <h3>2307 Sundown Lane, Austin,</h3>
          <p>Texas, USA</p>
        </div>

        <div class="contact">
          <i class="contact-icon fas fa-user"></i>
          <h3>+1 (862) 298 -3683</h3>
          <p>Mon-Fri 8:30am-6:30pm</p>
        </div>

        <div class="contact">
          <i class="contact-icon fas fa-envelope"></i>
          <h3>
            help@easyinvestment.net
          </h3>
          <p>24/7 online support</p>
        </div>
      </div>
    </section>

    <section id="contact-form">
      <form>
        <div class="center content-inputs">
          <vs-input class="vs-input" label-placeholder="Name" v-model="value" />
        </div>

        <div class="center content-inputs">
          <vs-input
            class="vs-input"
            label-placeholder="Email Address"
            v-model="value"
          />
        </div>

        <div class="center content-inputs">
          <textarea name="" id="" rows="10" placeholder="Message"></textarea>
        </div>
        <button class="form-btn">Submit Now</button>
      </form>
    </section>
  </div>
</template>
<script>
import Navbar from './Navbar';

export default {
  name: 'Contact',
  components: {
    Navbar,
  },
};
</script>

<style>
section {
  background-color: #041e37;
  color: #fff;
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.showcase-header {
  border-left: 0.6rem solid var(--brandcolor);
}

.showcase-div h1 {
  color: #fff;

  padding: auto;
}

#GetInTouch {
  color: var(--blackcolor);
  background-color: #fff;
  text-align: center;
}

#GetInTouch h2 {
  font-size: 45px;
  color: var(--brandcolor);
}

#GetInTouch h3 {
  font-size: 16px;
}

.contact {
  padding: 25px 5px;
}

.contact .contact-icon {
  color: var(--brandcolor);
  font-size: 2.7rem;
}

#contact-form {
  background-color: #fff;
  color: var(--blackcolor);
}

textarea {
  width: 100%;
  background: rgba(var(--vs-gray-2), 1);
  border: none;
  border-radius: 12px;
  font-family: var(--brandfont);
  padding: 10px;
  box-sizing: border-box;
}

textarea:focus {
  outline: none;
}

.content-inputs {
  margin: 10px 0;
}

.form-btn {
  display: block;
  background-color: var(--brandcolor);
  border: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 50px;
  margin: 0 auto;
}

form {
  font-size: 16px;
}

@media (min-width: 768px) {
  .showcase-div {
    padding: 5.5rem 20px;
  }

  .showcase-div h1 {
    color: #fff;

    padding: 5px;
  }

  .showcase-header {
    border-left: 0.6rem solid var(--brandcolor);
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    padding: 0px 10px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
    padding-right: 35px;
  }

  .contacts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  form {
    width: 50%;
    margin: 0 auto;
    font-size: 18px;
  }
}
</style>
