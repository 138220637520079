<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
        <div class="showcase-div">
          <p>Crypto <span class="brand">Currency</span></p>
          <p>Currency Solutions</p>
          <p>Investments</p>
          <div class="buttons">
            <router-link to="/login" class="btn1">Get Started</router-link>
            <router-link to="/about" class="btn2">Learn More</router-link>
          </div>
        </div>
      </div>
    </section>

    <div
      style="height:62px; background-color: #1D2330; overflow:hidden; box-sizing: border-box; border: 1px solid #282E3B; border-radius: 4px; text-align: right; line-height:14px; block-size:62px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #262B38;padding:1px;padding: 0px; margin: 0px; width: 100%;"
    >
      <div style="height:40px; padding:0px; margin:0px; width: 100%;">
        <iframe
          src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=no"
          width="100%"
          height="36px"
          scrolling="auto"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          border="0"
          style="border:0;margin:0;padding:0;"
        ></iframe>
      </div>
      <div
        style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"
      >
        <a
          href="https://coinlib.io"
          target="_blank"
          style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px"
          >Cryptocurrency Prices</a
        >&nbsp;by Coinlib
      </div>
    </div>

    <section id="icons">
      <div class="icon-div">
        <img src="../assets/images/icon_1.png" alt="" srcset="" />
        <h4>TRUSTED TRADING PLATFORM</h4>
        <p>Taking the time to manage your money really pay off.</p>
      </div>

      <div class="icon-div">
        <img src="../assets/images/icon_2.png" alt="" srcset="" />
        <h4>NO HIDDEN FEES</h4>
        <p>Just a one time fee, and you get your full investment returns.</p>
      </div>

      <div class="icon-div">
        <img src="../assets/images/icon_3.png" alt="" srcset="" />
        <h4>INSTANT TRADING</h4>
        <p>
          We place Instant Trades and Forecasts returns and rewards for the
          trades.
        </p>
      </div>

      <div class="icon-div">
        <img src="../assets/images/icon_4.png" alt="" srcset="" />
        <h4>SECURE AND TRANSPARENT</h4>
        <p>We are Transparent and places secure trades.</p>
      </div>
    </section>

    <section id="whyUs">
      <h2 class="heading">Why Choose Us?</h2>
      <p class="why-para">
        For more than 7 years, we’ve been empowering clients by helping them
        take control of their financial lives.
      </p>
      <div class="why-layout">
        <div class="reasons">
          <div class="reason">
            <img src="../assets/images/f2.png" alt="" srcset="" />
            <div class="reason-content">
              <h3>Fast Transaction</h3>
              <p>You can count on us for fast transactions</p>
            </div>
          </div>

          <div class="reason">
            <img src="../assets/images/f3.png" alt="" srcset="" />
            <div class="reason-content">
              <h3>Secure and Stable</h3>
              <p>We are a suberb and stable platform</p>
            </div>
          </div>

          <div class="reason">
            <img src="../assets/images/f4.png" alt="" srcset="" />
            <div class="reason-content">
              <h3>Coin Exchange</h3>
              <p>We are the best for coin exchange</p>
            </div>
          </div>
        </div>

        <div class="reason-image">
          <img src="../assets/images/bg3_new.png" alt="" srcset="" />
        </div>

        <div class="reasons">
          <div class="reason reason2">
            <img src="../assets/images/f5.png" alt="" srcset="" />
            <div class="reason-content">
              <h3>Ensured Profit</h3>
              <p>We ensure profit on investment</p>
            </div>
          </div>

          <div class="reason reason2">
            <img src="../assets/images/f6.png" alt="" srcset="" />
            <div class="reason-content">
              <h3>Retirement Spending needs</h3>
              <p>
                Forecasts retirement spending by current spending.
              </p>
            </div>
          </div>

          <div class="reason reason2">
            <img src="../assets/images/f1.png" alt="" srcset="" />
            <div class="reason-content">
              <h3>Coin Exchange</h3>
              <p>We are the best for coin exchange</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="chart">
      <div
        style="height:560px; background-color: #1D2330; overflow:hidden; box-sizing: border-box; border: 1px solid #282E3B; border-radius: 4px; text-align: right; line-height:14px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #262B38;padding:1px;padding: 0px; margin: 0px; width: 100%;"
      >
        <div style="height:540px; padding:0px; margin:0px; width: 100%;">
          <iframe
            src="https://widget.coinlib.io/widget?type=chart&theme=dark&coin_id=859&pref_coin_id=1505"
            width="100%"
            height="536px"
            scrolling="auto"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            border="0"
            style="border:0;margin:0;padding:0;line-height:14px;"
          ></iframe>
        </div>
        <div
          style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"
        >
          <a
            href="https://coinlib.io"
            target="_blank"
            style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px"
            >Cryptocurrency Prices</a
          >&nbsp;by Coinlib
        </div>
      </div>
    </section>

    <section id="pricing">
      <h2 class="heading">Our Pricing Plan</h2>
      <p>Exchange Transactions of your referred users Bitcoin</p>

      <div class="prices">
        <div class="price">
          <h3>BASIC PLAN</h3>
          <p>
            Try out our Basic Plan if you're looking to start out with something
            basic
          </p>
          <span>$500</span>
          <router-link to="/login" class="btn1">Get Started</router-link>
        </div>

        <div class="price">
          <h3>INTERMIDIATE PLAN BTC</h3>
          <p>Fancy an upgrade? try this out and be sure of maximum profit</p>
          <span>$1000</span>
          <router-link to="/login" class="btn1">Get Started</router-link>
        </div>

        <div class="price">
          <h3>STANDARD PLAN</h3>
          <p>
            Our Standard Plan give you access to more investment priiviledges.
            Try it out now!
          </p>
          <span>$2500</span>
          <router-link to="/login" class="btn1">Get Started</router-link>
        </div>

        <div class="price">
          <h3>PREMIUM PLAN</h3>
          <p>
            Our Premium Plan is the best investment Plan, with the best offers
            and priviledges
          </p>
          <span>$5000</span>
          <router-link to="/login" class="btn1">Get Started</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from './Navbar';
export default {
  name: 'Home',
  components: {
    Navbar,
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  padding: 10px;
}

.showcase {
  background: url('../assets/Wallpapers/slider_img1.png');
  background-position: center;
  background-size: cover;
}

.showcase-div {
  font-size: 35px;
  top: 30%;
  padding: 20% 20px;
}

.showcase-div p {
  margin: 10px;
  color: #fff;
  font-weight: lighter;
}

.brand {
  color: var(--brandcolor);
}

.btn1,
.btn2 {
  font-size: 15px;
  text-decoration: none;
  margin-right: 10px;
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: lighter !important;
  transition: 0.3s;
}

.btn1 {
  color: var(--blackcolor);
  background: var(--brandcolor);
  border: none;
}

.btn2 {
  color: #fff;
  border: 1px solid #fff;
}

.btn1:hover {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}

.btn2:hover {
  color: var(--blackcolor);
  background: var(--brandcolor);
  border: 1px solid var(--blackcolor);
}

#coins {
  background-color: var(--brandcolor);
  text-align: center;
}

#coins h4 {
  font-size: 16px;
  margin: 18px 0;
}

#coins span {
  font-size: 28px;
  font-weight: lighter;
}

#icons {
  background: var(--brandbackground);
}

#icons .icon-div {
  text-align: center;
  padding: 25px 20px;
}

#icons .icon-div h4 {
  color: var(--brandcolor);
}

#icons .icon-div p {
  color: #fff;
}

#icons img {
  width: 75px;
}

#whyUs {
  background-color: #041e37;
}

#chart {
  background-color: #041e37;
}

.why-para {
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
}

.reason {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 30px;
  color: #fff;
}

.reason img {
  width: 65px;
  height: 65px;
  grid-column: 1/2;
}

.reason-content {
  grid-column: 2/6;
}

.reason-content h3 {
  font-size: 17px;
}

.reason-content h3,
.reason-content p {
  margin: 5px 0;
}

.reason-image {
  margin: 50px 0;
}

.reason-image img {
  width: 100%;
}

#pricing {
  background-color: #1b334d;
  color: #fff;
}

.price {
  background-color: #041e37;
  padding: 3rem 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.price h3,
.price span {
  font-size: 20px;
}

.price span {
  display: block;
  margin: 20px 0;
}

.price .btn1 {
  display: inline-block;
  margin-right: 0;
}

@media (min-width: 768px) {
  .showcase-div {
    padding: 5.5rem 20px;
    font-size: 2rem;
  }

  #coins,
  #icons,
  .prices {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .why-layout {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  .reasons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reason2 img {
    grid-column: 5/6;
  }

  .reason2 .reason-content {
    grid-column: 1/5;
    grid-row: 1/2;
    text-align: end;
  }

  .why-para {
    font-size: 15px;
    width: 50%;
    margin: 0 auto;
  }
  .prices {
    grid-gap: 1.5rem;
  }

  #pricing p {
    text-align: center;
  }
}
</style>
