<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
        <div class="showcase-div">
          <div class="showcase-header">
            <h1>Our Blog</h1>
          </div>
        </div>
      </div>
    </section>

    <div
      style="height:62px; background-color: #1D2330; overflow:hidden; box-sizing: border-box; border: 1px solid #282E3B; border-radius: 4px; text-align: right; line-height:14px; block-size:62px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #262B38;padding:1px;padding: 0px; margin: 0px; width: 100%;"
    >
      <div style="height:40px; padding:0px; margin:0px; width: 100%;">
        <iframe
          src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=no"
          width="100%"
          height="36px"
          scrolling="auto"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          border="0"
          style="border:0;margin:0;padding:0;"
        ></iframe>
      </div>
      <div
        style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"
      >
        <a
          href="https://coinlib.io"
          target="_blank"
          style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px"
          >Cryptocurrency Prices</a
        >&nbsp;by Coinlib
      </div>
    </div>

    <section id="image-content">
      <div class="image-content">
        <div class="image">
          <img src="../assets/images/blog_img-768x499.jpg" alt="" sizes="" />
        </div>

        <div class="content">
          <h2>Great Success</h2>
          <span class="underline"></span>
          <p>
            Seems like a really cool blockchain. Like Libra, the Tezos company
            is headquartered in Switzerland. Unlike Libra their ICO in 2017
            raised a record-breaking $232 million, though it was shrouded with
            controversy and plenty of drama. The main focus of this unique
            blockchain is smart contracts and security token offerings, which is
            right in line with one of our goals in eToro to help tokenize all
            financial assets.
          </p>
        </div>
      </div>

      <div class="image-content">
        <div class="image">
          <img src="../assets/images/about_bg-770x500.png" alt="" sizes="" />
        </div>

        <div class="content">
          <h2>Retail Shakeout</h2>
          <span class="underline"></span>
          <p>
            Since landing in California last week, it’s become very clear to me
            how quickly the retail landscape is moving to the cloud. My family
            and I have been doing a fair bit of shopping since we got here but
            friends and relatives are giving us some funny looks. Why would we
            go out to a shopping center and walk around for hours instead of
            just searching on the internet to find exactly what we need in
            minutes?
          </p>
        </div>
      </div>

      <div class="image-content">
        <div class="image">
          <img src="../assets/Wallpapers/slider_img2.png" alt="" sizes="" />
        </div>

        <div class="content">
          <h2>From Setback To Advantage</h2>
          <span class="underline"></span>
          <p>
            It’s been five years in the making but the People’s Bank of China
            says that it is finally ready to release their own CBDC (Central
            Bank Digital Currency). Mr. Mu Changchun who heads the PBoC’s
            Payments and Settlement Division explained to the China 40 Finance
            Group over the weekend the complexities of the first cryptocurrency
            created by a major central bank. It may come as no surprise that the
            unique two-tier system has the PBoC in the top tier and Chinese
            commercial banks as the second tier. So, not exactly an open-source
            network like bitcoin. Changchun says that this particular strategy
            will work best with the needs of the Chinese economy.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from './Navbar';

export default {
  name: 'Blog',
  components: {
    Navbar,
  },
};
</script>

<style scoped>
section {
  background-color: #041e37;
  color: #fff;
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.showcase-header {
  border-left: 0.6rem solid var(--brandcolor);
}

.showcase-div h1 {
  color: #fff;

  padding: auto;
}

.image img {
  width: 100%;
  height: 200px;
}

.content {
  font-size: 15px;
  text-align: start;
}

#image-content {
  background-color: #fff;
  color: var(--blackcolor);
}

.underline {
  height: 4px;
  width: 40px;
  background-color: var(--brandcolor);
  display: block;
}

@media (min-width: 768px) {
  .showcase-div {
    padding: 5.5rem 20px;
  }

  .showcase-div h1 {
    color: #fff;

    padding: 5px;
  }

  .showcase-header {
    border-left: 0.6rem solid var(--brandcolor);
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    padding: 0px 10px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
    padding-right: 35px;
  }

  .image-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-bottom: 2rem;
  }

  .image img {
    height: auto;
  }
}
</style>
