<template>
  <div id="app">
    <div>
      <router-view></router-view>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from './components/Footer';

export default {
  name: 'App',
  components: {
    Footer,
  },
};
</script>

<style></style>
