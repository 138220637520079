<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
      </div>
    </section>

    <section id="layout">
      <div class="layout-div">
        <div class="sidebar">
          <div class="image-div">
            <vs-avatar size="100" circle>
              <img :src="currentUser.profileImage" alt="" />
            </vs-avatar>
            <div class="user-details">
              <h3>{{ currentUser.name }}</h3>
              <span>{{ currentUser.email }}</span>
            </div>
          </div>
          <ul>
            <li>
              <button @click="changeTab('Dashboard')">
                <i class=" list-icon fas fa-home"></i>Dashboard
              </button>
            </li>

            <li>
              <button @click="changeTab('LivePrices')">
                <i class=" list-icon fas fa-tags"></i>Live Prices
              </button>
            </li>

            <li>
              <button @click="active = !active">
                <i class="list-icon fas fa-wallet"></i>Deposit
              </button>
            </li>

            <li v-if="currentUser && currentUser.role === 'admin'">
              <button @click="goToUsers()">
                <i class=" list-icon fas fa-users"></i>Users
              </button>
            </li>

            <li>
              <button @click="changeTab('AccountSettings')">
                <i class=" list-icon fas fa-user-cog"></i>Account Settings
              </button>
            </li>

            <li>
              <button @click="logOutUser()">
                <i class=" list-icon fas fa-sign-out-alt"></i>Sign Out
              </button>
            </li>
          </ul>
        </div>

        <div class="display">
          <Dashboard v-if="activeTab === 'Dashboard'" />
          <LivePrices v-if="activeTab === 'LivePrices'" />

          <AccountSettings v-if="activeTab === 'AccountSettings'" />
        </div>
      </div>
    </section>

    <vs-dialog width="300px" not-center v-model="active">
      <vs-alert color="success" v-show="isCopied">
        Copied to clipboard
      </vs-alert>
      <template #header>
        <h4 class="header not-margin">Make A Deposit</h4>
      </template>

      <div class="con-content">
        <img src="../assets/images/barcode.jpg" style="width: 100%;" alt="" srcset="" />
        <div class="input-form">
          <vs-input
            value="1N7nrsPSfSEqaBv2aJktHHJHiwHArtCcQA"
            placeholder="Balance"
            disabled
          >
          </vs-input>
          <vs-button
            v-clipboard:copy="address"
            v-clipboard:success="displayAlert"
            border
          >
            <i class="fas fa-clipboard"></i>
          </vs-button>
        </div>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button class="ok-btn" @click="active = false" transparent>
            Ok
          </vs-button>
          <vs-button @click="active = false" dark transparent>
            Cancel
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import LivePrices from './LivePrices';

import AccountSettings from './AccountSettings';
import { bus } from '../main';

export default {
  name: 'Profile',
  components: {
    Navbar,
    Dashboard,
    LivePrices,

    AccountSettings,
  },

  data() {
    return {
      activeTab: 'Dashboard',
      active: false,
      address: '1N7nrsPSfSEqaBv2aJktHHJHiwHArtCcQA',
      isCopied: false,
    };
  },

  methods: {
    ...mapActions(['logout']),

    displayAlert() {
      this.isCopied = true;
    },
    changeTab(tab) {
      this.activeTab = tab;
    },

    goToUsers() {
      return this.$router.push('/users');
    },

    copyButton() {
      let newElement = document.createElement('input');
      newElement.value = this.address;
      newElement.select();
      document.execCommand('copy');
    },

    logOutUser() {
      this.logout();
    },
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  created() {
    bus.$on('tabChange', (tab) => {
      this.activeTab = tab;
    });
  },
};
</script>

<style scoped>
section {
  padding: 0;
  background: var(--blackcolor);
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.layout-div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  height: auto;
}

.sidebar {
  grid-column: 1/2;
  position: relative;
}

.display {
  grid-column: 2/6;
  padding: 2rem;
}

ul {
  list-style: none;
  padding: 20px 10px;
}

ul li {
  margin-bottom: 1.5rem;
}

ul button {
  background: transparent;
  color: var(--brandcolor);
  border: none;
  font-size: 18px;
  font-family: var(--brandfont);
}

.list-icon {
  margin-right: 10px;
}

.image-div {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  margin-top: 23px;
  text-align: start;
}

.image-div h3 {
  margin-bottom: 5px;
}

.con-content {
  text-align: center;
}

.con-footer {
  display: flex;
  justify-content: center;
}

.header {
  text-align: center;
}

.input-form {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width: 420px) {
  .layout-div {
    height: auto;
  }

  .sidebar {
    display: none;
  }

  .display {
    grid-column: 1/6;
    padding: 20px;
  }
}
</style>
