<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
        <div class="showcase-div">
          <div class="showcase-header">
            <h1>Our Team</h1>
          </div>
        </div>
      </div>
    </section>

    <div
      style="height:62px; background-color: #1D2330; overflow:hidden; box-sizing: border-box; border: 1px solid #282E3B; border-radius: 4px; text-align: right; line-height:14px; block-size:62px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #262B38;padding:1px;padding: 0px; margin: 0px; width: 100%;"
    >
      <div style="height:40px; padding:0px; margin:0px; width: 100%;">
        <iframe
          src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=no"
          width="100%"
          height="36px"
          scrolling="auto"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          border="0"
          style="border:0;margin:0;padding:0;"
        ></iframe>
      </div>
      <div
        style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"
      >
        <a
          href="https://coinlib.io"
          target="_blank"
          style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px"
          >Cryptocurrency Prices</a
        >&nbsp;by Coinlib
      </div>
    </div>

    <section id="team">
      <div>
        <h2>Our Team</h2>
        <p>
          We an entrusted team standby waiting to assist you
        </p>

        <div class="team">
          <div class="member">
            <img
              src="../assets/images/team_member1-400x480.jpg"
              alt=""
              srcset=""
            />
            <h3>Benard Liam</h3>
            <p>
              CEO
            </p>
          </div>

          <div class="member">
            <img
              src="../assets/images/team_member2-400x480.png"
              alt=""
              srcset=""
            />
            <h3>
              Nik Jorden
            </h3>
            <p>
              General Director
            </p>
          </div>

          <div class="member">
            <img
              src="../assets/images/team_member3-400x480.png"
              alt=""
              srcset=""
            />
            <h3>JD Scot</h3>
            <p>
              Regional Manager
            </p>
          </div>

          <div class="member">
            <img
              src="../assets/images/team_member4-400x480.png"
              alt=""
              srcset=""
            />
            <h3>Martin Martines</h3>
            <p>
              Assistant Manager
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from './Navbar';
export default {
  name: 'Team',
  components: {
    Navbar,
  },
};
</script>

<style>
#team {
  background-color: #fff;
  color: var(--blackcolor);
  text-align: center;
}

#team h2 {
  font-size: 45px;
  color: #27415a;
}

.member {
  border-bottom: 4px solid var(--brandcolor);
  margin-bottom: 4rem;
}

.member img {
  width: 100%;
}

.member h3 {
  margin: 15px 0;
}

.member p {
  font-size: 14px;
  text-align: center;
}

.member h3,
.member p {
  text-align: center;
}

@media (min-width: 768px) {
  #team p {
    width: 50%;
    margin: 15px auto;
  }

  .team {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  .member h3 {
    text-align: center;
  }
}
</style>
