import store from './store';

import Home from './components/Home.vue';
import About from './components/About.vue';
import Services from './components/Services.vue';
import Blog from './components/Blog.vue';
import Contact from './components/Contact.vue';
import Team from './components/Team.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import UpdatePassword from './components/UpdatePassword.vue';
import Users from './components/Users.vue';
import Profile from './components/Profile.vue';
import AccountSettings from './components/AccountSettings.vue';
import ResetPassword from './components/ResetPassword.vue';

export default [
  { path: '/', component: Home },
  { path: '/services', component: Services },
  { path: '/about', component: About },
  { path: '/blog', component: Blog },
  { path: '/contact', component: Contact },
  { path: '/team', component: Team },
  { path: '/login', component: Login },

  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/reset-password/:token', component: ResetPassword },

  {
    path: '/update-password',
    component: UpdatePassword,
    beforeEnter: (to, from, next) => {
      if (!store.state.auth.token) {
        next('/login');
      } else {
        next();
      }
    },
  },

  {
    path: '/users',
    component: Users,
    beforeEnter: (to, from, next) => {
      if (
        store.state.auth.token &&
        store.state.auth.user.data.role === 'admin'
      ) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (!store.state.auth.token) {
        next('/login');
      } else {
        next();
      }
    },
  },
  { path: '/profile/:sub', component: Profile },
  { path: '/acount-settings', component: AccountSettings },
];
