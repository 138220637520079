<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
      </div>
    </section>

    <div class="center examplex">
      <div class="search">
        <vs-input
          class="vs-input"
          v-model="search"
          @keyup="filter"
          type="text"
          icon-after
          placeholder="Search"
        >
          <template #icon>
            <i class="fas fa-search"></i>
          </template>
        </vs-input>
      </div>

      <span class="loader" v-if="loading">
        <half-circle-spinner
          :animation-duration="1000"
          :size="50"
          :color="color"
        />
      </span>

      <vs-table v-if="!loading" class="vs-table" striped>
        <template #thead>
          <vs-tr>
            <vs-th>
              Users
            </vs-th>
            <vs-th>
              Email
            </vs-th>
            <vs-th>
              Total Deposit
            </vs-th>

            <vs-th>
              Wallet Balance
            </vs-th>

            <vs-th>
              Total Profit
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="user._id" v-for="user in copiedUsers" :data="user">
            <vs-td @click="setUser(user)" class="vs-td">
              <vs-avatar circle badge>
                <img :src="user.profileImage" alt="" />
              </vs-avatar>

              {{ user.name }}
            </vs-td>

            <vs-td>{{ user.email }}</vs-td>
            <vs-td>${{ user.totalDeposit }}</vs-td>
            <vs-td>${{ user.walletBalance }}</vs-td>
            <vs-td>${{ user.totalProfit }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <vs-dialog width="300px" not-center v-model="active">
      <template #header>
        <vs-avatar circle badge>
          <img :src="user.profileImage" alt="" />
        </vs-avatar>
        <h4 class="not-margin">
          Edit <b v-if="user">{{ `${user.name.split(' ')[0]}'s` }}</b> Balance
        </h4>
      </template>

      <div class="con-content">
        <vs-input
          type="Number"
          v-model="payload.totalDeposit"
          placeholder="Total Deposit"
        ></vs-input>
      </div>

      <div class="con-content">
        <vs-input
          type="Number"
          v-model="payload.walletBalance"
          placeholder="Wallet Balance"
        ></vs-input>
      </div>

      <div class="con-content">
        <vs-input
          type="Number"
          v-model="payload.totalProfit"
          placeholder="Total Profit"
        ></vs-input>
      </div>

      <template #footer>
        <div class="con-footer">
          <vs-button
            class="ok-btn success"
            color="danger"
            @click="add"
            transparent
          >
            Top Up
          </vs-button>
          <vs-button class="ok-btn danger" @click="subtract" transparent>
            Reduce
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import Navbar from './Navbar';
import { mapActions, mapGetters } from 'vuex';
import { HalfCircleSpinner } from 'epic-spinners';

export default {
  name: 'Users',
  components: {
    Navbar,
    HalfCircleSpinner,
  },

  data: () => ({
    active: false,
    user: '',
    copiedUsers: '',
    payload: {
      totalProfit: '',
      totalDeposit: '',
      walletBalance: '',
    },
    search: '',

    originalUsers: '',
    loading: true,
    color: '#e9d16f',
  }),

  methods: {
    ...mapActions(['getUsers', 'addToWallet', 'removeFromWallet']),

    setUser(user) {
      this.active = !this.active;
      this.user = user;
    },

    async add() {
      this.active = false;

      this.loading = true;

      await this.addToWallet({
        id: this.user.id,
        payload: this.payload,
      });
      await this.initUsers();
      this.loading = false;
    },

    async subtract() {
      this.active = false;

      this.loading = true;

      await this.removeFromWallet({ id: this.user.id, payload: this.payload });
      await this.initUsers();

      await this.initUsers();

      this.loading = false;
    },

    filter() {
      if (this.search !== '') {
        this.copiedUsers = this.copiedUsers.filter((user) => {
          const doesNameExist = user.name.toLowerCase().includes(this.search.toLowerCase());
          const doesEmailExist = user.email.toLowerCase().includes(this.search.toLowerCase());
          return (doesNameExist || doesEmailExist)
        });
      } else {
        this.copiedUsers = this.users;
      }
    },

    async initUsers() {
      await this.getUsers();
      this.loading = false;

      this.copiedUsers = this.users;
    },
  },

  computed: {
    ...mapGetters(['users']),
  },

  async created() {
    await this.initUsers();
  },
};
</script>

<style scoped>
.vs-alert {
  text-align: center;
}

.loader {
  display: flex;
  justify-content: center;
}

.danger {
  color: red !important;
}

.success {
  color: green !important;
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.vs-td {
  display: flex;
  align-items: center;
}

.vs-td img {
  margin-right: 10px;
}

.center {
  padding: 10px;
}

.con-content {
  margin-bottom: 10px;
}

.con-footer {
  display: flex;
  justify-content: space-between;
}

.ok-btn {
  color: var(--brandcolor);
}

.vs-input {
  /* padding: 15px; */
  margin: 1rem auto;
}

@media (min-width: 768px) {
  .vs-table {
    width: 60%;
    margin: 0 auto;
  }

  .center {
    padding: 2rem;
  }
  .search {
    width: 60%;
    margin: 0 auto;
  }
}
</style>
