<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
        <div class="showcase-div">
          <div class="showcase-header">
            <h1>About Us</h1>
          </div>
        </div>
      </div>
    </section>

    <div
      style="height:62px; background-color: #1D2330; overflow:hidden; box-sizing: border-box; border: 1px solid #282E3B; border-radius: 4px; text-align: right; line-height:14px; block-size:62px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #262B38;padding:1px;padding: 0px; margin: 0px; width: 100%;"
    >
      <div style="height:40px; padding:0px; margin:0px; width: 100%;">
        <iframe
          src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=no"
          width="100%"
          height="36px"
          scrolling="auto"
          marginwidth="0"
          marginheight="0"
          frameborder="0"
          border="0"
          style="border:0;margin:0;padding:0;"
        ></iframe>
      </div>
      <div
        style="color: #626B7F; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"
      >
        <a
          href="https://coinlib.io"
          target="_blank"
          style="font-weight: 500; color: #626B7F; text-decoration:none; font-size:11px"
          >Cryptocurrency Prices</a
        >&nbsp;by Coinlib
      </div>
    </div>

    <section>
      <div class="image-content">
        <div class="image">
          <img src="../assets/images/crypto_ab2.jpg" alt="" sizes="" />
        </div>

        <div class="content">
          <h4>Our About Important Descriptions</h4>
          <h2>History</h2>
          <p>
            Initially specialising in Crypto broker we gradually expanded the
            range of products and currently our customers can choose from over
            1000 CFD or Spread Betting instruments across Cryptocurrency,
            Indices, Shares, Commodities, Financials, commodities and ETFs..
          </p>

          <div>
            <router-link class="content-link" to="/">read more</router-link>
          </div>
        </div>
      </div>

      <div class="image-content">
        <div class="content">
          <h4>Our About Important Descriptions</h4>
          <h2>Culture</h2>
          <p>
            With our customers’ trust, our expertise in delivering first-class
            trading environment and innovation at the heart of the company we
            look forward to expanding our reach and providing investment
            opportunities to retail, professional and institutional traders
            across the world..
          </p>

          <div>
            <router-link class="content-link" to="/">read more</router-link>
          </div>
        </div>
        <div class="image">
          <img src="../assets/images/crypto_ab.jpg" alt="" sizes="" />
        </div>
      </div>
    </section>

    <section>
      <h2>More About Crypto</h2>
      <div class="crypto-divs">
        <div class="crypto-div">
          <div class="image">
            <img src="../assets/images/about_crypto1.jpg" alt="" srcset="" />
          </div>

          <p>Exchange Transactions of your referred users Bitcoin</p>
        </div>

        <div class="crypto-div">
          <div class="image">
            <img src="../assets/images/about_crypto2.jpg" alt="" srcset="" />
          </div>

          <p>
            On the other hand, we denounce with righteous indignation and
            dislike men who are so beguiled and demoralized by the charms..
          </p>
        </div>

        <div class="crypto-div">
          <div class="image">
            <img src="../assets/images/about_crypto3.jpg" alt="" srcset="" />
          </div>

          <p>
            Get ultra-competitive spreads and commissions across all asset
            classes. Receive even better rates as your volume increases.
          </p>
        </div>
      </div>
    </section>

    <section id="weAre">
      <div>
        <h1>We Are:</h1>
        <p>Your trusted trading platfrom for any trade conditions</p>
      </div>

      <div class="image">
        <img src="../assets/images/in-wave-mockup-3.png" alt="" srcset="" />
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from './Navbar';
export default {
  name: 'About',
  components: {
    Navbar,
  },
};
</script>

<style>
section {
  background-color: #041e37;
  color: #fff;
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.showcase-header {
  border-left: 0.6rem solid var(--brandcolor);
}

.showcase-div h1 {
  color: #fff;

  padding: auto;
}

.image img {
  width: 100%;
}

.content {
  font-size: 15px;
}

.content-link {
  background: var(--brandcolor);
  text-decoration: none;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  text-transform: uppercase;
  display: inline-block;
  margin: 15px 0;
}

#weAre h1 {
  color: var(--brandcolor);
}

@media (min-width: 768px) {
  .showcase-div {
    padding: 5.5rem 20px;
  }

  .showcase-div h1 {
    color: #fff;

    padding: 5px;
  }

  .showcase-header {
    border-left: 0.6rem solid var(--brandcolor);
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    padding: 0px 10px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.2);
    padding-right: 35px;
  }

  .image-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-bottom: 2rem;
  }

  .crypto-divs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }

  #weAre {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }

  #weAre .image {
    grid-column: 2/4;
  }
}
</style>
