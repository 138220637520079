<template>
  <div class="hidden">
    <vs-navbar
      class="vs-navbar"
      shadow
      square
      center-collapsed
      v-model="active"
    >
      <!-- NAVBAR -->
      <template #right>
        <button class="menu-icon" @click="activeSidebar = !activeSidebar">
          <i class="fas bar-icon fa-bars"></i>
        </button>

        <router-link to="/profile" v-if="isLoggedIn" class="image-div">
          <vs-avatar size="30" circle>
            <img :src="currentUser.profileImage" alt="" />
          </vs-avatar>
          <div class="user-details">
            <h3>{{ currentUser.name }}</h3>
          </div>
        </router-link>

        <div v-if="!isLoggedIn">
          <router-link to="/login" class="desktop-link btn1">Login</router-link>
          <router-link to="/register" class="desktop-link btn2"
            >Sign Up</router-link
          >
        </div>
      </template>
      <vs-navbar-item :active="active == 'guide'" id="guide">
        <router-link class="nav-item" to="/about">About</router-link>
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'docs'" id="docs">
        <router-link class="nav-item" to="/blog">Blog</router-link>
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'components'" id="components">
        <router-link class="nav-item" to="/services">Our Services</router-link>
      </vs-navbar-item>

      <vs-navbar-item :active="active == 'license'" id="license">
        <router-link class="nav-item" to="/team">Our Team</router-link>
      </vs-navbar-item>
      <vs-navbar-item :active="active == 'components'" id="components">
        <router-link class="nav-item" to="/contact">Contact Us</router-link>
      </vs-navbar-item>
      <template #left>
        <router-link to="/"
          ><img
            class="logo"
            src="../assets/images/leobit_logo.png"
            alt=""
            srcset=""
        /></router-link>
      </template>
    </vs-navbar>
    <vs-sidebar
      class="vs-sidebar"
      absolute
      v-model="active"
      :open.sync="activeSidebar"
    >
      <template #logo>
        <div class="logo-div">
          <img src="../assets/images/leobit_logo.png" alt="" srcset="" />
        </div>
      </template>

      <div v-if="isLoggedIn" class="sidebar-user">
        <vs-avatar size="80" circle>
          <img :src="currentUser.profileImage" alt="" />
        </vs-avatar>
        <div class="user-details">
          <h3>{{ currentUser.name }}</h3>
        </div>
      </div>

      <vs-sidebar-item id="home">
        <router-link class="router-link" to="/"> Home</router-link>
      </vs-sidebar-item>
      <vs-sidebar-item id="about">
        <router-link class="router-link" to="/about"> About</router-link>
      </vs-sidebar-item>
      <vs-sidebar-item id="blog">
        <router-link class="router-link" to="/blog">Blog</router-link>
      </vs-sidebar-item>

      <vs-sidebar-item id="services">
        <router-link class="router-link" to="/services"
          >Our Services</router-link
        >
      </vs-sidebar-item>
      <vs-sidebar-item id="team">
        <router-link class="router-link" to="/contact">Contact Us</router-link>
      </vs-sidebar-item>
      <vs-sidebar-item id="testimonial">
        <router-link class="router-link" to="/team">Our Team</router-link>
      </vs-sidebar-item>

      <vs-sidebar-item
        v-if="isLoggedIn && currentUser.role === 'admin'"
        id="testimonial"
      >
        <router-link class="router-link" to="/users">Users</router-link>
      </vs-sidebar-item>

      <vs-sidebar-group v-if="isLoggedIn">
        <template #header>
          <vs-sidebar-item arrow>
            <router-link class="router-link" to="/profile">Profile</router-link>
          </vs-sidebar-item>
        </template>

        <vs-sidebar-item id="prices">
          <div @click="changeTab('LivePrices')">Live Prices</div>
        </vs-sidebar-item>

        <vs-sidebar-item id="account">
          <div @click="changeTab('AccountSettings')">Account Settings</div>
        </vs-sidebar-item>

        <vs-sidebar-item id="logout">
          <div @click="logOutUser()">Sign Out</div>
        </vs-sidebar-item>
      </vs-sidebar-group>

      <vs-sidebar-item v-if="!isLoggedIn" id="login">
        <router-link class="router-link" to="/login">Login</router-link>
      </vs-sidebar-item>

      <vs-sidebar-item v-if="!isLoggedIn" id="signup">
        <router-link class="router-link" to="/register">Sign Up</router-link>
      </vs-sidebar-item>
    </vs-sidebar>
  </div>
</template>

<script>
import { bus } from '../main';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  data: () => ({
    active: '',
    activeSidebar: false,
    home: true,
  }),

  methods: {
    ...mapActions(['logout']),

    changeTab(tab) {
      this.activeSidebar = false;
      bus.$emit('tabChange', tab);
      if (this.$route.fullPath !== '/profile') {
        return this.$router.push('/profile');
      }
    },

    logOutUser() {
      this.logout();
    },
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),

    isActive() {
      return this.$router.currentRoute.fullPath;
    },
  },
};
</script>

<style scoped>
.image-div {
  display: flex;
  align-items: center;
  color: #fff;
}

.sidebar-user {
  padding-left: 15px;
}

.sidebar-user h3 {
  margin: 5px 0;
  color: #fff;
  font-size: 18px;
}

.image-div h3 {
  font-size: 16px;
  margin-left: 5px;
}

.nav-item {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.btn1,
.btn2 {
  font-size: 16px;
  text-decoration: none;
  margin-right: 10px;
  padding: 6px 15px;
  border-radius: 50px;
  font-weight: lighter !important;
  transition: 0.3s;
}

.btn1 {
  color: var(--blackcolor);
  background: var(--brandcolor);
  border: none;
}

.btn2 {
  color: #fff;
  border: 1px solid #fff;
}

.btn1:hover {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}

.btn2:hover {
  color: var(--blackcolor);
  background: var(--brandcolor);
  border: 1px solid var(--blackcolor);
}

.vs-sidebar {
  background-color: #041e37;
}

.router-link {
  color: #fff;
  text-decoration: none;
}
.logo-div {
  width: 100%;
  justify-content: start;
  padding: 2px 10px;
}

.logo {
  width: 60px;
}

.menu-icon {
  color: var(--brancolor);
  background: transparent;
  border: none;
}

.vs-navbar {
  background: transparent;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
}

.vs-navbar .nav-item {
}

@media (max-width: 600px) {
  .desktop-link {
    display: none;
  }
}

@media (max-width: 420px) {
  .image-div {
    display: none;
  }
}

@media (min-width: 768px) {
  .menu-icon {
    display: none;
  }

  .menu-icon .bar-icon {
    color: var(--brandcolor) !important;
  }
}
</style>
