<template>
  <div>
    <section class="showcase showcase-base">
      <div>
        <Navbar />
      </div>
    </section>

    <section id="contact-form">
      <vs-alert v-if="passwordMessage" class="vs-alert" color="success">
        {{ passwordMessage }}
      </vs-alert>

      <vs-alert v-if="!loading && errorMsg" class="vs-alert" color="danger">
        {{ errorMsg }}
      </vs-alert>

      <form @submit.prevent="sendEmail">
        <i class="user-icon fas fa-user-circle"></i>

        <h2>Forgot Password</h2>

        <div class="center content-inputs">
          <vs-input class="vs-input" placeholder="Email" v-model="email" />
        </div>

        <button v-if="!loading" class="form-btn">Submit Now</button>

        <span class="loader" v-if="loading">
          <half-circle-spinner
            :animation-duration="1000"
            :size="30"
            :color="color"
          />
        </span>

        <div class="auth">
          <span
            >Don't have an account?<router-link
              class="auth-link"
              to="/register"
            >
              Create One</router-link
            ></span
          >
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { HalfCircleSpinner } from 'epic-spinners';
import Navbar from './Navbar';
import { bus } from '../main';

export default {
  name: 'ForgotPassword',
  components: {
    Navbar,
    HalfCircleSpinner,
  },

  data: () => ({
    email: '',
    color: '#e9d16f',
    loading: false,
  }),

  methods: {
    ...mapActions(['forgotPassword']),

    sendEmail() {
      this.loading = true;
      this.forgotPassword({ email: this.email });
    },
  },

  computed: {
    ...mapGetters(['passwordMessage', 'errorMsg']),
  },

  created() {
    bus.$on('stopLoading', () => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
}

.error {
  display: block;
  position: relative;
  bottom: 10px;
  color: #f57f6c;
  text-align: start;
  margin-top: 5px;
  font-size: 14px;
  padding: 10px 0 0 0;
}

.vs-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.showcase {
  background: url('../assets/images/bg_page_title.png');
  background-position: center;
  background-size: cover;
}

.showcase-header {
  border-left: 0.6rem solid var(--brandcolor);
}

.showcase-div h1 {
  color: #fff;
  padding: auto;
}

.contact {
  padding: 25px 5px;
}

.contact .contact-icon {
  color: var(--brandcolor);
  font-size: 2.7rem;
}

#contact-form {
  background-color: #fff;
  color: var(--blackcolor);
}

.content-inputs {
  margin: 10px 0;
}

.form-btn {
  display: block;
  background-color: var(--brandcolor);
  border: none;
  padding: 10px 20px;
  color: #fff;
  /* border-radius: 50px; */
  margin: 15px auto;
}

form {
  font-size: 16px;
  text-align: center;
  padding: 4rem 20px;
}

.user-icon {
  font-size: 5.5rem;
  color: var(--brandcolor);
}

.auth {
  display: flex;
  flex-direction: column;
  color: var(--blackcolor);
}

.auth span {
  display: block;
  margin: 5px auto;
}

.auth .auth-link {
  text-decoration: none;
  color: var(--brandcolor);
}

@media (min-width: 768px) {
  form {
    width: 45%;
    margin: 0 auto;
    font-size: 18px;
  }
}
</style>
