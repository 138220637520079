import axios from 'axios';
import { router } from '../../main';

axios.defaults.baseURL = 'https://server-ewdw.onrender.com/api/v1';

const state = {
  user: '',
  token: null,
  errorMsg: '',
  notifications: '',
  users: '',
  passwordMessage: '',
};

const getters = {
  isLoggedIn: (state) => state.user,
  currentUser: (state) => state.user.data,
  errorMsg: (state) => state.errorMsg,
  signUpErrors: (state) => state.signUpErrors,
  users: (state) => state.users,
  passwordMessage: (state) => state.passwordMessage,
};

const actions = {
  //Login User In
  async login({ commit }, { email, password }) {
    try {
      const response = await axios.post('/users/login', {
        email,
        password,
      });

      commit('setUser', response.data.data);
      commit('setToken', response.data.token);
      router.push({ path: 'Profile' });
    } catch (error) {
      console.log(error.response);

      commit('setError', error.response.data.message);
    }
  },

  async signIn({ commit }, payload) {
    try {
      const response = await axios.post('/users/signup', payload);

      commit('setUser', response.data.user);
      commit('setToken', response.data.token);
      router.push({ path: '/profile' });
    } catch (error) {
      const { message } = error.response.data;

      commit('setError', message);
    }
  },

  async logout({ commit }) {
    try {
      commit('logOut');
      router.push({ path: '/' });
    } catch (error) {
      console.log(error);
    }
  },

  async attempt({ commit }, token) {
    commit('setToken', token);

    try {
      const response = await axios.get('/users/me');

      commit('setUser', response.data.data);
    } catch (error) {
      commit('setToken', null);
      commit('setUser', null);
    }
  },

  async updateMe({ commit }, payload) {
    try {
      const response = await axios.patch('/users/updateMe', payload.payload);

      commit('updateUser', response.data.data);
    } catch (error) {
      const { message } = error.response.data;

      commit('setError', message);
      console.log(error.response);
    }
  },

  async updatePassword({ commit }, payload) {
    try {
      await axios.patch('/users/updateMyPassword', payload);
    } catch (error) {
      console.log(error.response);
      const { message } = error.response.data;

      commit('setError', message);
    }
  },

  async getUsers({ commit }) {
    try {
      const response = await axios.get('/users');

      commit('setUsers', response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  },

  async addToWallet({ commit }, { id, payload }) {
    try {
      const response = await axios.patch(
        `/users/${id}/topupWalletAndProfit`,
        payload
      );

      commit('addWallet', response.data.data);
    } catch (error) {
      console.log(error.response);

      commit('setError', error.response);
    }
  },

  async removeFromWallet({ commit }, { id, payload }) {
    try {
      const response = await axios.patch(
        `/users/${id}/reduceWalletAndProfit`,
        payload
      );

      commit('addWallet', response.data.data);
    } catch (error) {
      console.log(error.response);

      commit('setError', error.response);
    }
  },

  async forgotPassword({ commit }, email) {
    try {
      const response = await axios.post('/users/forgotPassword', email);

      commit('setMessage', response.data.message);
    } catch (error) {
      console.log(error.response);
      const { message } = error.response.data;

      commit('setError', message);
    }
  },

  async resetPassword({ commit }, { payload, token }) {
    try {
      const response = await axios.patch(
        `/users/resetPassword/${token}`,
        payload
      );

      commit('setMessage', response.data.message);
      router.push({ path: 'Login' });
    } catch (error) {
      console.log(error.response);
      commit('setError', error.response.data);
    }
  },

  async updateUserData({ commit }, { payload, id }) {
    try {
      const response = await axios.patch(`/users/${id}`, payload);

      commit('setUser', response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  },
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  setToken: (state, token) => (state.token = token),
  logOut: (state) => {
    state.user = null;
    state.token = null;
    localStorage.removeItem('token');
  },
  setNotification: (state, notifications) =>
    (state.notifications = notifications),

  updateNotification: (state, notification) => {
    state.notifications.find((el) => el.id === notification.id).read = true;
  },

  setMessage: (state, message) => (state.passwordMessage = message),

  updateUser: (state, updatedUser) => {
    state.user = updatedUser;
  },

  setError: (state, errorMsg) => (state.errorMsg = errorMsg),

  setUsers: (state, users) => (state.users = users.data),

  addWallet: (state, updatedUser) => {
    const index = state.users.findIndex(
      (user) => user._id === updatedUser.data._id
    );

    state.users.splice(index, 1, updatedUser.data);
    return updatedUser;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
